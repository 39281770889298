<script>
const IFRAME_ID = 'nave-iframe'

export default {
	lang: 'shop',
	inject: ['refreshOrder', 'getAppMessageDialog'],
	props: {
		order: Object,
	},
	data() {
		return {
			loading: null,
		}
	},
	computed: {
		stkey() {
			return `nave-opened-${this.order.id}`
		},
	},
	methods: {
		async startIframe(src) {
			return new Promise((resolve) => {
				document.getElementById(IFRAME_ID)?.remove()
				const iframe = document.createElement('iframe')
				iframe.id = IFRAME_ID
				let styles = [
					`position: fixed`,
					`top: 0`,
					`left: 0`,
					`width: 100%`,
					`height: 100%`,
					`z-index: 999999`,
					`background: rgba(255,255,255,0.9)`,
				]
				iframe.setAttribute('style', styles.join(';'))
				iframe.addEventListener('load', async () => {
					resolve(iframe)
				})
				iframe.addEventListener('message', (...args) => {
					console.log(...args)
				})
				iframe.src = src
				document.body.appendChild(iframe)
			})
		},
		async startNavePayment() {
			this.loading = true
			let response = null
			let isMobile = !!this.$b.m
			response = await this.$shopApi.post({
				url: `/order/${this.order.id}/resolve-payment`,
				loading: false,
				data: { isMobile, callbackUrl: window.location.href },
				onMessage: false,
				onError: false,
			})

			if (!response.success) {
				response = await this.$shopApi.post({
					url: `/order/${this.order.id}/resolve-payment`,
					loading: false,
					data: { isMobile: !isMobile, callbackUrl: window.location.href },
				})
			}

			if (response.success) {
				const { checkoutData, productionMode, platform } = response.data
				if (isMobile) {
					let url = isMobile && checkoutData?.data?.redirect_to
					url = url || checkoutData?.data?.checkout_url
					if (url) {
						window.localStorage.setItem(this.stkey, true)
						window.location.href = url
						return
					}
				}

				// show iframe on dekstop or mobile fallback
				const baseUrl = productionMode
					? `https://ecommerce.ranty.io/${platform}/nave/checkout`
					: `https://e3-ecommerce.ranty.io/${platform}/nave/checkout`

				const iframeSrc =
					baseUrl +
					`?payment_request_id=${checkoutData.data.payment_request_id}` +
					`&qr_data=${checkoutData.data.qr_data}`

				await this.startIframe(iframeSrc)
			}

			this.loading = false

			// return true
		},
		onIframeMessage(event) {
			// Check the type of the message
			if (event.data.type === 'PAYMENT_MODAL_RESPONSE') {
				document.getElementById(IFRAME_ID)?.remove()
				this.refreshOrder()
			}
		},
	},
	watch: {
		loading(value, oldValue) {
			if (value) {
				this.getAppMessageDialog().open({
					loading: true,
					title: 'Iniciando Pago con NAVE',
					dialogId: 'nave-loading',
				})
			} else if (oldValue === true) {
				this.getAppMessageDialog().close('nave-loading')
			}
		},
	},
	async mounted() {
		if (this.$b.d || !window.localStorage.getItem(this.stkey)) {
			this.startNavePayment()
		}

		window.addEventListener('message', this.onIframeMessage)
	},
	beforeDestroy() {
		document.getElementById(IFRAME_ID)?.remove()
		window.removeEventListener('message', this.onIframeMessage)
	},
}
</script>

<template>
	<div>
		<div
			class="font-2"
			v-html="$lang('Para realizar el pago, <b>haz click en el siguiente botón</b>.')"
		></div>
		<div class="py-5">
			<Button color="success" @click="startNavePayment()" :loading="loading">
				{{ 'Pagar con Nave' | lang }}
			</Button>
		</div>
	</div>
</template>
